<template>
    <div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HotelPublic",
    metaInfo: {
      title: "Hotel",
      titleTemplate: "%s | Aigent",
    },
    created() {
      // open assetlinks.json file
        window.location.href = "assetlinks.json";
    },
  }
  </script>
  
  <style>
  
  </style>